import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { Router, ActivatedRoute } from '@angular/router';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ValidateConfirmpass } from 'src/app/components/confirmpass.validator';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';

@Component({
  selector: 'app-tenant-profile',
  template: `
    <div class="row">
      <div class="col-10 offset-1 col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-0 mt-sm-2">
        <div class="row">
          <div class="col-12 col-sm-5 col-md-5">
            <h1 class="mb-0">Perfil</h1>
            <p>Configura tu información.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/services/service">Agregar usuario</!-->
          </div>
        </div>
      </div>
      
      <div class="col-10 offset-1 col-sm-12 col-md-3 offset-sm-0 offset-md-3 mt-2 mt-sm-4">
        <div class="list-group mb-5"> 
          <div class="list-group-item py-3">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="userForm" (ngSubmit)="onSubmit(userForm)">
                  <fieldset>
                    <legend class="mt-2 mb-4" style="font-size: 19px; font-weight: 800;">Datos personales</legend>
                    <div class="avatar-container text-center" style="left: 22%;">
                      <div class="image-container">
                        <img [src]="avatarURL" width="100%" height="100%">
                      </div> 
                      <label for="avatar-input-file">
                        <div class="icon-wrapper">
                          <input type="file" id="avatar-input-file" (change)="loadAvatar($event)" accept="image/*"/>
                          <fa-icon [icon]="icons.faEdit" class="text-white"></fa-icon>
                        </div>
                      </label>
                    </div>
                    <div class="form-group mt-3">
                      <label for="name" class="form-label text-sm">Nombre(s)</label>
                      <input type="text" formControlName="name" class="form-control rounded-3" id="name" placeholder="Nombre">
                      <div *ngIf="tenantForm['name'].touched && tenantForm['name'].invalid">
                        <div *ngIf="tenantForm['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
                      </div>
                    </div>  
                    <div class="form-group mt-2">
                      <label for="last_name" class="form-label text-sm">Primer Apellido</label>
                      <input type="text" formControlName="last_name" class="form-control rounded-3" id="last_name">
                      <div *ngIf="tenantForm['last_name'].touched && tenantForm['last_name'].invalid">
                        <div *ngIf="tenantForm['last_name'].errors?.['required']" class="text-danger text-sm">Los apellidos es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-2">
                      <label for="last_name_m" class="form-label text-sm">Segundo Apellido</label>
                      <input type="text" formControlName="last_name_m" class="form-control rounded-3" id="last_name_m">
                      <div *ngIf="tenantForm['last_name_m'].touched && tenantForm['last_name_m'].invalid">
                        <div *ngIf="tenantForm['last_name_m'].errors?.['required']" class="text-danger text-sm">Los apellidos es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-2">
                      <label for="dob" class="form-label text-sm">Fecha de nacimiento</label>
                      <input type="date" formControlName="dob" class="form-control rounded-3" id="dob">
                      <div *ngIf="tenantForm['dob'].touched && tenantForm['dob'].invalid">
                        <div *ngIf="tenantForm['dob'].errors?.['required']" class="text-danger text-sm">La fecha de nacimiento es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-2">
                      <label for="emailInput" class="form-label text-sm">Correo electrónico</label>
                      <input type="email" formControlName="email" class="form-control rounded-3" id="emailInput">
                      <div *ngIf="tenantForm['email'].touched && tenantForm['email'].invalid">
                        <div *ngIf="tenantForm['email'].errors?.['required']" class="text-danger text-sm">El correo es requerido.</div>
                        <div *ngIf="tenantForm['email'].errors?.['email']" class="text-danger text-sm">El correo no es válido.</div>
                      </div>
                    </div>  
                    <div class="form-group mt-2">
                      <label for="phone" class="form-label text-sm">Teléfono</label>
                      <input type="tel" formControlName="phone" class="form-control rounded-3" id="phone">
                      <div *ngIf="tenantForm['phone'].touched && tenantForm['phone'].invalid">
                        <div *ngIf="tenantForm['phone'].errors?.['required']" class="text-danger text-sm">El teléfono es requerido.</div>
                        <div *ngIf="tenantForm['phone'].errors?.['minlength']" class="text-danger text-sm">El teléfono no es válido (10 dígitos).</div>
                      </div>
                    </div>
                  </fieldset>
                  <div class="form-group mt-3 text-start" *ngIf="permissions?.modules?.admin?.contracts?.write">
                    <div class="d-flex flex-wrap">
                      <div class="badge bg-primary me-2 mb-2" *ngIf="user?.thsm_id">THSM {{user.thsm_id}}</div>
                      <div class="badge bg-secondary me-2 mb-2" *ngIf="!user?.thsm_id">THSM -</div>

                      <div class="badge bg-primary me-2 mb-2" *ngIf="user?.trade_id">Trade {{user.trade_id}}</div>
                      <div class="badge bg-secondary me-2 mb-2" *ngIf="!user?.trade_id">Trade -</div>

                      <div class="badge bg-primary me-2 mb-2" *ngIf="user?.account_number">Cuenta {{user.account_number}}</div>
                      <div class="badge bg-secondary me-2 mb-2" *ngIf="!user?.account_number">Cuenta -</div>
                    </div>                    
                  </div>
                  <div class="form-group mt-3 text-end">
                    <button class="btn btn-outline-primary" [disabled]="loading">
                      <fa-icon [icon]="icons.faSave" class="me-2" *ngIf="!loading"></fa-icon>
                      <fa-icon [icon]="icons.faCircleNotch" [spin]="true" class="me-2" *ngIf="loading"></fa-icon>
                      Actualizar</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-10 offset-1 col-sm-12 offset-sm-0 col-sm-4 col-md-6 mt-2 mt-sm-4 mb-5">
        <div class="list-group" *ngIf="user != null">
          <div class="bg-white border-bottom-0 pb-0 pt-4 ps-4 pe-5">
            <ul class="nav nav-tabs border-bottom-0" #tabs>
              <li class="nav-item">
                <a #menu1 class="nav-link active" data-toggle="tab" href="#password" (click)="tab($event)" >Contraseña</a>
              </li>
              <li class="nav-item">
                <a #menu1 class="nav-link" data-toggle="tab" href="#address" (click)="tab($event)">Ubicaciones</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#fiscal" (click)="tab($event)">Datos físcales</a>
              </li>
              <li class="nav-item" *ngIf="permissions?.modules?.admin?.contracts?.write">
                <a class="nav-link" data-toggle="tab" href="#public_record" (click)="tab($event)">Registro público</a>
              </li>
              <li class="nav-item" *ngIf="public_record !== null">
                <a class="nav-link" data-toggle="tab" href="#representante" (click)="tab($event)">Representante legal</a>
              </li>
            </ul>
          </div>
          <div class="list-group-item py-3 p-sm-4">
            <div #tabContent class="tab-content">
              <div id="password" class="tab-pane password active ps-3 pe-3">
                <app-form-user-password
                  [user]="user" 
                ></app-form-user-password>
              </div>
              <div id="address" class="tab-pane address ps-0 pe-0">
                <!-- app-form-tenant-address
                  [user]="user"
                  [address]="addressData"
                  [catalogs]="catalogs"
                  (save)="saveAddress($event)"
                ></!-->
                <app-tenant-units
                  [user]="user"
                  [units]="units"
                  (updateData)="loadTenant($event)"
                ></app-tenant-units>
              </div>
              <div id="fiscal" class="tab-pane fiscal ps-3 pe-3">
                <app-form-tenant-fiscal
                  [user]="user" 
                  [fiscal]="fiscalData"
                  [catalogs]="catalogs"
                  (save)="updateFiscal($event)"
                ></app-form-tenant-fiscal>
              </div>
              <div id="public_record" class="tab-pane public_record ps-3 pe-3">
                <app-form-public-record 
                  [publicRecord]="publicRecord" 
                  (publicRecordData)="createPublicRecord($event)"
                ></app-form-public-record>
              </div>
              <div id="representante" class="tab-pane representante ps-3 pe-3" *ngIf="public_record!==null">
                <app-form-public-record 
                  [publicRecord]="legal_rep" 
                  [isLegal]="true"
                  [related]="public_record.id" 
                  (publicRecordData)="createLegalRep($event)"
                ></app-form-public-record>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  `,
  styleUrls: []
})
export class TenantProfileComponent implements OnInit {
  @ViewChild('tabs') tabs!: ElementRef | undefined;
  @ViewChild('tabContent') tabContent!: ElementRef | undefined;
  
  user: any = null;
  permissions: any = null;
  avatarURL: any = '';
  userId: number = 0;
  loading: boolean = false;

  userForm: FormGroup = new FormGroup({});
  addressData: any = null;
  fiscalData: any = null;
  publicRecord: any = null;
  public_record: any = null;
  legal_rep: any = null;

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  passwordPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@$!%*?&].{8,}';

  imageError: string = "";
  isImageSaved: boolean = false;
  cardImageBase64: string = "";
  previewImagePath: string = "";

  units: any[] = [];

  constructor(
    private store: SettlementsService,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public catalogs: CatalogsService,
    public icons: IconsService,
    public images: ImageService
  ) {
    this.buildForm();

    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    
    this.activateRoute.params.subscribe(params => {
      this.userId = params['id'];
      this.loading = true;
      this.loadTenant(this.userId);      
    });

  }

  ngOnInit(): void {
    
  }

  tab(e: any) {
    e.preventDefault();
    //
    this.tabs?.nativeElement.querySelectorAll('.nav-link').forEach((tab: any) => {
      tab.classList.remove('active');
    });
    this.tabContent?.nativeElement.querySelectorAll('.tab-pane').forEach((tabPane: any) => {
      tabPane.classList.remove('active');
    });
    e.target.classList.add('active');
    this.tabContent?.nativeElement.querySelector(`.${e.target.href.split('#')[1]}`)?.classList.add('active');
  }

  buildForm() {
    this.userForm = this.fb.group({
      id: [''],
      email: ['', [Validators.required, Validators.email]],
      name: [''],
      last_name: [''],
      last_name_m: [''],
      phone: ['', [Validators.required, Validators.minLength(10)]],
      dob: [''],
    });
  }

  get tenantForm() {
    return this.userForm.controls;
  }

  loadTenant(id: number) {
    this.store.getTenant(id).subscribe((data: any) => {
      console.log('loadTenant data => ', data);
      this.user = data;
      this.userForm.patchValue(data);

      if (data.avatar) {
        this.avatarURL = this.images.buildURL(data.avatar);
      }

      if (data.public_record_id) {
        this.store.getPublicRecord(data.public_record_id).subscribe((publicRecord: any) => {
          this.publicRecord = publicRecord;
        });
      }

      this.loading = false;
    });

    this.loadUnits(this.userId);
      
    this.store.getTenantAddress(this.userId).subscribe((address: any) => {
      this.addressData = address;
    });

    this.store.getTenantFiscal(this.userId).subscribe((fiscal: any) => {
      this.fiscalData = fiscal;
    });


  }

  loadUser(id: number) {
    this.store.getTenant(this.userId).subscribe((data: any) => {
      console.log('loadUser data => ', data);
      let el = data.avatar;

      this.avatarURL = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
      console.log("avatarURL => ", this.avatarURL);
    });
  }

  loadUnits(id: number) {
    this.units = [];
    this.store.getTenantUnits(this.userId).subscribe((data: any) => {
      console.log('loadUnits => ', data);
      this.units = data;
    });
  }

  loadAvatar(event: any): void {

    this.imageError = "";

    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (event.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        // return false;
      }

      // if (!_.includes(allowed_types, event.target.files[0].type)) {
        // this.imageError = 'Only Images are allowed ( JPG | PNG )';
        // return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          // console.log(img_height, img_width);
          if (img_height > max_height && img_width > max_width) {
            this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
            // return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.previewImagePath = imgBase64Path;
            console.log('imgBase64Path => ', imgBase64Path);
            console.log('previewImagePath => ', this.previewImagePath);
            let payload = {
              id: this.userId,
              data: this.previewImagePath
            }
            console.log('event => ', event.target.files[0]);
            this.store.updateTenantAvatar(this.userForm.value.id, payload).subscribe({
              next: (data: any) => {
                this.toastr.success('Avatar actualizado correctamente.');
                this.loadUser(this.userId);
              },
              error: (err: any) => {
                console.log(err);
                this.toastr.error('Ocurrió un error al actualizar el avatar.');
              }
            });
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }    
  }

  onSubmit(form: any) {
    console.log("update profile => ", form);
    console.log('Address data => ', this.addressData);
    this.loading = true;

    const profile = {
      id: this.userId,
      name: form.value.name,
      last_name: form.value.last_name,
      last_name_m: form.value.last_name_m,
      phone: form.value.phone,
      email: form.value.email,
      dob: form.value.dob
    };
    const profile_address = {
      id: this.addressData ? this.addressData.id : null,
      country: 'MX',
      address: form.value.street,
      state: form.value.state,
      city: form.value.city,
      zip_code: form.value.zip,
      tenant_id: this.userId
    };

    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      this.toastr.error('No está completa la información.', 'Error');
      this.loading = false;
      return;
    }

    this.store.updateTenant(this.userId, profile).subscribe((data: any) => {
      this.loadTenant(this.userId);
      this.toastr.success('Perfil actualizado correctamente.');
      /*if (this.addressData == null) {
        this.store.createTenantAddress(profile_address).subscribe((data: any) => {
          console.log('data => ', data);
          // this.route.navigate(['/tenant']);
          this.toastr.success('Perfil actualizado correctamente.');
        });
      } else {
        this.store.updateTenantAddress(profile_address.id, profile_address).subscribe((data: any) => {
          console.log('data => ', data);
          this.toastr.success('Perfil actualizado correctamente.');
        });
      }*/
    });
  }

  saveAddress(event: any) {
    console.log("saveAddress => ", event);
    
    if (event.id != '') {
      this.store.updateTenantAddress(event.id, event).subscribe({
        next: (data: any) => {
          this.toastr.success('Dirección actualizada correctamente.', 'Éxito');
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Error al actualizar la dirección.', 'Error');
        }
      });
    } else {
      console.log('createTenantAddress => ', event);
      this.store.createTenantAddress(event).subscribe({
        next: (data: any) => {
          this.toastr.success('Dirección actualizada correctamente.', 'Éxito');
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Error al actualizar la dirección.', 'Error');
        }
      });
    }
  }

  /*updatePassword() {
    console.log(this.userPwdForm);
    if (this.userPwdForm.invalid) {
      this.userPwdForm.markAllAsTouched();
      this.toastr.error('No está completa la información.', 'Error');
      return;
    }

    this.store.updateTenant(this.userId, this.userPwdForm.value).subscribe({
      next: (data: any) => {
        this.toastr.success('Contraseña actualizada correctamente.', 'Éxito');
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Error al actualizar la contraseña.', 'Error');
      }
    });

    // this.updatePassword
  }*/

  updateFiscal(event: any) {
    console.log('updateFiscal => ', event);
    if (event.id == '') {
      this.store.createTenantFiscal(event).subscribe({
        next: (data: any) => {
          this.toastr.success('Datos fiscales actualizados correctamente.', 'Éxito');
        },
        error: (err: any) => {
          this.toastr.error('Error al actualizar los datos fiscales.', 'Error');
        }
      });
    } else {
      this.store.updateTenantFiscal(event.id, event).subscribe({
        next: (data: any) => {
          this.toastr.success('Datos fiscales actualizados correctamente.', 'Éxito');
        },
        error: (err: any) => {
          this.toastr.error('Error al actualizar los datos fiscales.', 'Error');
        }
      });
    }
  }

  createPublicRecord(event: any) {
    console.log("create ", event);

    const payload: any = event;
    payload.profile_id = this.user.id;

    if (event.id) {
      this.store.updatePublicRecord(event.id, event).subscribe({
        next: (res: any) => {
          console.log('updatePublicRecord res', res);
          this.toastr.success('El registro público se actualizó correctamente.', 'Actualizado');
          this.store.updateTenant(this.userId, { public_record_id: res.id }).subscribe((data: any) => {
          // this.router.navigate(['/perfiles']);
          });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al actualizar el registro público.', 'Error');
        }
      });
    } else {
      this.store.createPublicRecord(event).subscribe({
        next: (res: any) => {
          console.log('createPublicRecord res', res);
          this.toastr.success('El registro público se creó correctamente.', 'Creado');
          this.store.updateTenant(this.userId, { public_record_id: res.id }).subscribe((data: any) => {
            // this.router.navigate(['/perfiles']);
          });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al crear el registro público.', 'Error');
        }
      });
    }
  }

  createLegalRep(event: any) {
    const payload: any = event;
    payload.profile_id = this.user.id;

    if (event.id) {
      this.store.updatePublicRecord(event.id, event).subscribe({
        next: (res: any) => {
          console.log('updatePublicRecord res', res);
          this.toastr.success('El registro público se actualizó correctamente.', 'Actualizado');
          // this.store.updateProfile(this.profile.id, { public_record_id: res.id }).subscribe((data: any) => {
          // this.router.navigate(['/perfiles']);
          // });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al actualizar el registro público.', 'Error');
        }
      });
    } else {
      this.store.createPublicRecord(event).subscribe({
        next: (res: any) => {
          console.log('createPublicRecord res', res);
          this.toastr.success('El registro público se creó correctamente.', 'Creado');
          // this.store.updateProfile(this.profile.id, { public_record_id: res.id }).subscribe((data: any) => {
            // this.router.navigate(['/perfiles']);
          // });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al crear el registro público.', 'Error');
        }
      });
    }
  }
}
