import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-tenant-units',
  template: `
  <div class="list-group" *ngIf="units">
    <div class="list-group-item" *ngFor="let unit of units">
      <div class="d-flex">
        <div>
          <h5 class="mb-0">{{unit.settlement_name}} {{unit.name}}</h5>
          <p class="mb-0">{{unit.country}} {{unit.state}} {{unit.city}} {{unit.outer_number}}</p>
          <p class="mb-0">{{unit.settlement_street}}</p>
          <p class="mb-0">C.P. {{unit.zip}}</p>
        </div>
        <div class="ms-auto text-end">
          <div class="badge badge-pill bg-primary" *ngIf="unit.thsm_address_id">THSM Address ID {{unit.thsm_address_id}}</div><br>
          <div class="badge badge-pill bg-primary" *ngIf="unit.thsm_address_id">Contact ID {{unit.contact_id}}</div>
          <button type="button" class="btn btn-outline-primary btn-sm" *ngIf="!unit.thsm_address_id" (click)="getThsmId(unit)">Obtener id</button>
        </div>
      </div>
      
    </div>
  </div>
  `,
  styleUrls: []
})
export class TenantUnitsComponent {
  @Input() user: any;
  @Input() units: any;
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private store: SettlementsService
  ) { }

  getThsmId(unit: any) {
    console.log('THSM Address ID:', unit.region_id);
    this.store.getThsmAddressId(this.user.id, { tenant_id: this.user.id, unit_id: unit.id}).subscribe((res: any) => {
      console.log('THSM Address ID:', res);
      this.updateData.emit(this.user.id);
    });
  }
}
