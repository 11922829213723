import { AfterViewInit, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-tenant-fiscal',
  template: `
    <form [formGroup]="userFiscalForm" (ngSubmit)="updateFiscal()">
      <!-- legend class="mt-3 mb-4" style="font-size: 19px; font-weight: 800;">Datos fiscales</!-->
      <div class="form-group mt-3">
        <input class="form-check-input form-check-input-lg" type="checkbox" id="flexCheckDefault" formControlName="periodic">
        <label class="form-check-label ms-3" for="flexCheckDefault">
          Solicitar factura al generar recibo
        </label>
      </div>
      <div class="form-group mt-3">
        <label for="rfc" class="form-control-label text-sm">RFC</label>
        <input type="text" class="form-control" id="rfc" formControlName="rfc">
        <div *ngIf="ffc['rfc']?.touched && ffc['rfc']?.invalid">
          <div *ngIf="ffc['rfc'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="razon_social" class="form-control-label text-sm">Razón social</label>
        <input type="text" class="form-control" id="razon_social" formControlName="razon_social">
        <div *ngIf="ffc['razon_social']?.touched && ffc['razon_social']?.invalid">
          <div *ngIf="ffc['razon_social'].errors?.['required']" class="text-danger text-sm">La razón social es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="zip_code" class="form-control-label text-sm">Código postal</label>
        <input type="text" class="form-control" id="zip_code" formControlName="zip_code">
        <div *ngIf="ffc['zip_code']?.touched && ffc['zip_code']?.invalid">
          <div *ngIf="ffc['zip_code'].errors?.['required']" class="text-danger text-sm">El Código postal es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="regimen_fiscal" class="form-control-label text-sm">Regímen fiscal</label>
        <select class="form-select" formControlName="regimen_fiscal">
          <option value="" selected>Selecciona un régimen fiscal</option>
          <option *ngFor="let regimen of catalogs.regimenes" [value]="regimen.id" [selected]="regimen.id == ffc['regimen_fiscal'].value">{{regimen.id}} - {{regimen.name}}</option>
        </select>
        <div *ngIf="ffc['regimen_fiscal']?.touched && ffc['regimen_fiscal']?.invalid">
          <div *ngIf="ffc['regimen_fiscal'].errors?.['required']" class="text-danger text-sm">El Regimen fiscal es requerido.</div>
        </div>
      </div>
      
      <div class="form-group mt-3">
        <label for="uso_cfdi" class="form-control-label text-sm">Uso CFDI</label>
        <select class="form-select" formControlName="uso_cfdi">
          <option value="" selected>Selecciona un uso de CFDI</option>
          <option *ngFor="let usoCFDI of catalogs.usosCFDI" [value]="usoCFDI.id">{{usoCFDI.id}} - {{usoCFDI.name}}</option>
        </select>
        <div *ngIf="ffc['uso_cfdi']?.touched && ffc['uso_cfdi']?.invalid">
          <div *ngIf="ffc['uso_cfdi'].errors?.['required']" class="text-danger text-sm">El Uso de CFDI es requerido.</div>
        </div>
      </div>

      <div class="form-group mt-5 mb-2 text-end">
        <button type="submit" class="btn btn-outline-primary">Guardar</button>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormTenantFiscalComponent implements AfterViewInit {
  @Input() catalogs: any;
  @Input() fiscal: any;
  @Input() user: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  userFiscalForm: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.initForm();
  }

  ngAfterViewInit(): void {    
    console.log('FISCAL user', this.user);
    if (this.user) {
      this.userFiscalForm.patchValue({ tenant_id: this.user.id });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fiscal'] && changes['fiscal'].currentValue) {
      console.log('CHANGES FISCAL ==> ', changes['fiscal'].currentValue);
      this.userFiscalForm.patchValue(changes['fiscal'].currentValue);
    }
  }

  initForm() {
    console.log('buildFiscalForm !!!!');
    this.userFiscalForm = this.fb.group({
      id: [''],
      periodic: [false],
      rfc: ['', Validators.required],
      razon_social: ['', Validators.required],
      zip_code: ['', Validators.required, Validators.minLength(5)],
      regimen_fiscal: ['', Validators.required],
      uso_cfdi: ['', Validators.required],
      tenant_id: ['']
    });
  }

  get ffc() {
    return this.userFiscalForm.controls;
  }

  updateFiscal() {
    if (this.userFiscalForm.invalid) {
      this.toastr.error('No está completa la información.', 'Error');
      this.userFiscalForm.markAllAsTouched();
      return;
    }

    this.save.emit(this.userFiscalForm.value);
  }

}
