import { AfterViewInit, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-user-password',
  template: `
    <form [formGroup]="userPwdForm" (ngSubmit)="updatePassword()">
      <div class="form-group mt-3">
        <label for="password" class="form-control-label text-sm">Contraseña</label>
        <input type="password" class="form-control" id="password" formControlName="password" placeholder="Contraseña">
        <div *ngIf="userPwd['password']?.touched && userPwd['password']?.invalid" class="text-start">
          <div *ngIf="userPwd['password'].errors?.['required']" class="text-danger text-sm">La contraseña es requerido.</div>
          <small *ngIf="userPwd['password'].errors?.['minlength']" class="text-danger text-sm">La contraseña contener un mínimo 8 caracteres.</small>
          <small *ngIf="userPwd['password'].errors?.['maxlength']" class="text-danger text-sm">La contraseña contener un máximo 20 caracteres.</small>
          <small *ngIf="!userPwd['password'].errors?.['minlength'] && userPwd['password'].errors?.['pattern']" class="text-danger text-sm">La contraseña debe contener al menos un número (0-9), al menos una letra minúscula (a-z), al menos una letra mayúscula (A-Z), y al menos un caracter especial ($@#!%*?&)</small>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="password_confirmation" class="form-control-label text-sm">Confirmar Contraseña</label>
        <input type="password" class="form-control" id="password_confirmation" formControlName="password_confirmation" placeholder="Repetir contraseña">
        <div *ngIf="userPwd['password_confirmation']?.touched && userPwd['password_confirmation']?.invalid" class="text-start">
          <div *ngIf="userPwd['password_confirmation'].errors?.['required']" class="text-danger text-sm">La confirmación de la contraseña es requerida.</div>
          <small *ngIf="userPwd['password_confirmation'].errors?.['noMatch']" class="text-danger text-sm">Las contraseñas deben coincidir.</small>
        </div>
      </div>
      <div class="form-group mt-5 text-end">
        <button type="submit" class="btn btn-warning">Actualizar</button>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormUserPasswordComponent implements AfterViewInit {
  @Input() user: any = {};
  @Input() catalogs: any = {};

  userPwdForm: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder
  ) {
    this.initForm();
  }

  ngAfterViewInit(): void {
    console.log('PWDS user', this.user);
    if (this.user) {
      this.userPwdForm.patchValue({ user_id: this.user.id });
    }
  }

  get userPwd() { return this.userPwdForm.controls; }

  initForm() {
    this.userPwdForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$@#!%*?&]).{8,20}$/)]],
      password_confirmation: ['', [Validators.required]],
      user_id: ['']
    });
  }

  updatePassword() {}
}
